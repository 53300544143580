<template>
    <div id="feedbackPage">
      <div class="container">
        <textarea v-model="subInfo.content" class="textarea" placeholder="ご意見をお願いします"></textarea>
        <Uploader class="uploader" :list="subInfo.list" @afterRead="afterRead" :max="9" ></Uploader>
        <button class="submit-btn" @click="onSubmit" type="button">送信</button>
      </div>
    </div>
</template>

<script>
import { Uploader } from '@/components';
export default {
  name: 'Feedback',
  components: {
    Uploader
  },
  data () {
    return {
      subInfo: {
        content: '',
        list: [],
        newArr: []
      }
    };
  },
  created () {
  },
  mounted () {

  },
  watch: {},
  methods: {
    onSubmit () {
      console.log(this.subInfo.list);
      const { subInfo: { content, newArr }, $utils, $router } = this;
      if ($utils.verifyEmpty(content, 'フィードバックの内容を記入してください。')) {
        this.$http.post('mine/createProblem', {
          'picture': newArr.join(';'),
          'problem': content
        }).then((res) => {
          $utils.successToast({
            message: '送信済み。貴重なご意見ありがとうございます。',
            onClose: () => {
              $router.back();
            }
          });
        });

      }
    },
    afterRead (e) {
      // this.subInfo.list.push(...e.map((item) => ({ src: item.content, file: item.file })));
      const formData = new FormData();
      const { subInfo: { list, newArr }, $utils } = this;
      formData.append('file', e[0].file);
      this.$http.post('upload/image/avatar', formData, {
        showLoading: true
      }).then((res) => {
        $utils.successToast('アップロード成功');
        list.push(...e.map((item) => ({ src: item.content, file: item.file })));
        newArr.push(res.data.fileName);
      });
    }
  }
};
</script>

<style scoped lang="less">
  #feedbackPage {
    padding: 34px 30px;

    .textarea {
      height: 224px;
      margin-bottom: 20px;
      font-size: 26px;
    }

    .submit-btn {
      margin-top: 80px;
    }
  }
</style>